@import '~react-datepicker/dist/react-datepicker.css';

$font-family: 'Lato', sans-serif, serif;

// Colors
$white: #fff;
$primary-green: #55b336;
$dark-green: #00b72b;
$dark-gray: #707070;
$gray-200: #e4e4e5;
$gray-600: #767676;
$light-gray: #d4d4d4;
$text-dark: #292929;
$gray: #495964;
$gray-darker: #2d373e;
$red: #ff0202;
$blue: #2185d0;
$blue-darker: #0272ce;
$sidebar-bg: #3a3a3a;
$sidebar-bg-secondary: #343434;

// Font sizes
$font-size-xxl-6: 110px;
$font-size-xxl-5: 64px;
$font-size-xxl-4: 46px;
$font-size-xxl-3: 42px;
$font-size-heading: 30px;
$font-size-xxl-1: 22px;
$font-size-xxl: 20px;
$font-size-xl: 18px;
$font-size-large: 16px;
$font-size-medium: 14px;
$font-size-medium-xs: 12px;
$font-size-small: 11px;
$font-size-xs: 10px;

// Font Weights
$font-weight-bold: 700;
$font-weight-normal: 400;
$font-weight-thin: 300;

// Border radius
$border-radius-small: 5px;
$border-radius-medium: 10px;
$border-radius-big: 18px;
$radius-lg: 18px;

//Spacing
$side-menu-padding-right: 40px;

// Media Queries
@mixin for-mobile {
  @media screen and (max-width: 470px) {
    @content;
  }
}

@mixin for-tablet-portrait-and-less {
  @media screen and (max-width: 780px) {
    @content;
  }
}

@mixin for-tablet-and-less {
  @media screen and (max-width: 1000px) {
    @content;
  }
}

@mixin for-s-laptop-and-less {
  @media screen and (max-width: 1280px) {
    @content;
  }
}

@mixin for-s-laptop-and-more {
  @media screen and (min-width: 1280px) {
    @content;
  }
}

@mixin for-small-laptop-and-less {
  @media screen and (max-width: 1440px) {
    @content;
  }
}

@mixin for-big-laptop {
  @media screen and (min-width: 1280px) and (max-width: 1600px) {
    @content;
  }
}

@mixin modalAbsoluteFooter($property) {
  position: absolute;

  bottom: -75px;
  #{$property}: 60px;

  @include for-s-laptop-and-less {
    bottom: -55px;
    #{$property}: 10px;
  }
}

:export {
  primaryGreen: $primary-green;
  darkGray: $dark-gray;
  gray200: $gray-200;
  gray600: $gray-600;
  lightGray: $light-gray;
  textDark: $text-dark;
  gray: $gray;
  grayDarker: $gray-darker;
  red: $red;
  blue: $blue;
  sidebarBg: $sidebar-bg;
  sidebarBgSecondary: $sidebar-bg-secondary;
}
