/* ==========================================================================
   HEADER.
   ========================================================================== */


header.page-header {
  position: sticky!important;
  top: 0;
  z-index: 5!important;

  /* Sticky menu panel.
     ========================================================================== */
  & > .menu.fixed {
    background: rgba(255, 255, 255, 0.8);

    width: calc(100% - #{$header-spacing * 2});

    height: $header-height;
    padding: 0 $header-spacing;
    margin: $header-spacing;
    @media (min-width: $screen-xl-min) {
      height: $header-height-xl;
    }
    @media (max-width: $screen-xs-max) {
      width: calc(100% - #{$header-spacing-xs * 2});
      height: $header-height-xs;
      padding: 0 $header-spacing-xs;
      margin: $header-spacing-xs;
    }
    @media (max-width: 400px) {
      padding: 0;
    }


    /* Menu items wrapper.
       ========================================================================== */

    .menu-container.ui.container {
      width: 100%;
      max-width: $outer-container-width !important;
      padding: 0 var(--size--brikks-spacing);

      /* Logo (Left) */
      .brand-logo {
        margin-top: -10px;
        padding-left: 0;
      }

      /* Right menu */
      .user-menu {
        display: flex;
        flex-direction: row;
        align-items: center;

        .arrange-brikks i {
          padding-left: 10px;
        }
      }

    } /* END: Menu items wrapper. */

  } /* END: Sticky menu panel */

} /** END: HEADER **/
