@import "~global/styles";

/* ==========================================================================
   BRIKK BrikkModal.
   ========================================================================== */

.brikk-modal.ui.modal {
  width: 97%;

  min-height: 875px;
  background: #fff;
  border-radius: $brikk-border-radius;

  /* BrikkModal header.
     ========================================================================== */

  .header {
    border-bottom: none;
    background: transparent;
   // color: $brand-text-color-dark;

    /* Logo */
    img.logo {
      width: 75px;
      margin: 0 !important;
    }

    /* Main header */
    h2.header {
      font-size: 30px;
      font-weight: normal;
      margin-top: 0.6em;
      margin-bottom: 0;
      line-height: 1em;
    }

    /* Sub-header */
    h3.header {
      font-size: 1rem;
      font-weight: 300;
      text-transform: uppercase;
      margin-top: 0.3em;
    }

    /* Close icon */
    img.close-action {
      transform: rotate(45deg);
      width: 16px;
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
    }
  }

  /* BrikkModal body.
     ========================================================================== */

  & > .content {
    background: transparent;
    padding: 2rem 0!important;

    @media (max-width: $screen-xs-max) {
      .column {
        padding: 2rem 0 !important;
      }
    }

    .heading {
      font-size: 22px;
      font-weight: bold;
      text-transform: uppercase;

      &.with-icon {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        word-break: break-word;

        .path {
          font-size: 3rem;
          margin-left: 0.3em;
          margin-right: 0;
          color: $brand-primary-color-green;
        }
      }
    }

    .sub-heading {
      font-size: 16px;
      font-weight: normal;
      margin: 0.5em 0 1em;
      min-height: 1.4em;
    }

    table {

      tr {

        td {
          font-size: 12px;
          white-space: normal;
          padding: 0 1em;
          height: 40px;

        }
      }
    }

    .ui.stackable.grid {
      justify-content: space-around;

      & > * {
        padding: 1rem !important;
        margin: 0!important;
      }

      & {
        margin: 0!important;
      }

      & > .column {
        max-width: 400px;

        @media (max-width: $screen-xs-max) {
          max-width: 500px;
          padding-left: 0!important;
          padding-right: 0!important;
        }
      }
    }

    .ui.items {
      .item {
        @media (max-width: $screen-xs-max) {
          flex-direction: row;
        }

        min-height: 35px;
        margin: 0 0 15px;

        i, img {
          font-size: 1.5em;
          width: 20px;
          color: $brand-primary-color-green;
          margin-right: 0.7em;
          margin-top: 5px;
        }

        .content {
          .header {
            font-size: 12px;
            text-transform: uppercase;
            font-weight: normal;
          }
          .description {
            font-size: 1rem;
            font-weight: 600;
            margin: 0;
            line-height: 1em;
          }
        }
      }
    }


    /* Custom tabs styles */
    .tabular-menu {
      .fluid.menu {
        display: flex!important;
        flex-wrap: wrap!important;
        width: fit-content !important;

        & > * {
          padding: 10px 15px!important;
        }

        & > *:before {
          display: none;
        }
      }
      .column:first-of-type {
        padding-right: 0;
        max-width: 120px;
      }
      .column:last-of-type {
        flex-grow: 1;
      }

      .menu {
        width: fit-content !important;

        .item {
          text-align: center;
          word-break: break-word;
          padding: 1em 0.5em;

          svg {
            display: block;
            margin: 0 auto 0.5em;;
            min-height: 25px;
            width: 25px;

            @media screen and (max-width: 1300px) {
              margin: 0 10px 0.5em auto;
            }
          }

          &.active {
            background: $brand-primary-color-green;
            color: white;

            svg {
              fill: white;
            }
          }

        }
      }
    }
  }
}

/* Mina avtal modal */
.ui.modal.brikk-modal .content .mina-avtal-modal {

  .mina-avtal-image {
    margin-top: 4em;
    margin-left: auto;
    margin-right: auto;
    width: 200px;
  }

  .mina-avtal-table {
    td {
      width: 100px;
    }
  }

  .body.parkering {
    border: 1px solid rgba(34,36,38,.15);
    border-radius: 0.28rem;
    position: relative;
    margin-bottom: 100px;
    padding: 1px;

    table {
      border: none;
      margin: 0;
    }
  }

  .body.documents {
    button {
      margin: 0 auto;
      display: block;
    }
  }

  .carousel {

    .carousel__slider {
      $avtals-height: 30px;
      height: calc(100% + #{$avtals-height});
      padding-top: $avtals-height;
      margin-top: -$avtals-height;

      .parkering-avtals {
        position: absolute;
        top: -$avtals-height;
        height: $avtals-height;
        width: 100%;
        text-align: center;
      }
    }

    table {
      word-break: break-word;
    }

    > button {
      position: absolute;
      bottom: -10px;
      border: none;
      background: none;
      visibility: hidden;
      height: 0;
      font-size: 0;

      &:after {
        visibility: visible;
        display: block;
        font-weight: bold;
        font-size: 3rem;
        color: $brand-secondary-color-gray;
      }

      &[disabled] {
        opacity: 0.5;
      }

      &.carousel__back-button {
        left: 50px;
        &:after {
          content: '\00ab';
        }
      }
      &.carousel__next-button {
        right: 50px;
        &:after {
          content: '\00bb';
        }
      }
    }

    .carousel__dot-group {
      position: absolute;
      text-align: center;
      left: 0;
      right: 0;
      bottom: -50px;
      pointer-events: none;

      button.carousel__dot {
        pointer-events: all;
        width: 10px;
        height: 10px;
        padding: 0;
        margin: 5px;
        border-radius: 50%;
        border: none;
        background-color: lightgray;

        &.carousel__dot--selected {
          background-color: $brand-secondary-color-gray;
        }
      }
    }
  }
}

/* Mina Uppgifter modal */
.ui.modal.brikk-modal > .content .grid.my-details-modal {


  .column.user-profile {
    @include for-tablet-portrait-and-less {
      display: grid;
      justify-items: center;
    }


    .card.ui {
      @include for-tablet-portrait-and-less {
        width: 100%;
        max-width: 300px !important;
      }

      img {
        padding: 1em 2em;
      }
      .content {
        text-align: center;
      //  color: $brand-text-color-dark;

        .name {
          font-weight: bold;
        }
      }
    }
  }

  .column.user-info {
    display: flex;
    flex-direction: column;

    .heading {
      @include for-tablet-portrait-and-less {
        text-align: center;
      }
    }

    .ui.form {

      @include for-tablet-portrait-and-less {
        display: flex;
        justify-content: center;
      }

      flex-grow: 1;

      .ui.items {
        padding-top: 1.5em;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        .item {

          .description {

            .field.disabled {
              opacity: 1;
            }

            .ui.input {
              width: 100%;

              input {
                font-size: 1rem;
                font-weight: 600;
                padding: 0.3em 0.5em;
                margin-left: -0.5em;
                box-sizing: border-box;
              }

              &.disabled {
                opacity: 1;

                input {
                  background: transparent !important;
                  border: 1px solid transparent;
                }
              }

            }

          }

        }

        & > button {
          margin-top: auto;
        }
      }
    }
  }

  .column.contact-info {
    @media (min-width: $screen-xs-max) {
      max-width: unset;
    }

    @include for-tablet-portrait-and-less {
      .heading {
        text-align: center;
      }
    }

    display: flex;
    flex-direction: column;

    .body.contact {
      flex-grow: 1;

      form {
        padding: 1rem 0.5rem !important;
      }

      & > button {
        margin: auto;
      }

      .contact-persons {

        .label {
          font-size: 0.93em;
          font-weight: bold;
          margin-bottom: 0.28rem;
        }

        .fields {
          flex-wrap: wrap;
        }
      }
    }
  };
}


/* Maintenance modal (Underhåll) */
.ui.modal.brikk-modal > .content .grid.maintenance-modal {

  .column {

    .selection.dropdown {

      /* Options groups divider. */
      .select-group-divider {
        pointer-events: none !important;
      }
    }

    .planned-maintenance {
      overflow-x: auto;
    }
  }
}

.brikk-modal {
  .heading {
    font-size: 22px !important;
    font-weight: bold !important;
    text-transform: uppercase !important;

    &.with-icon {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      word-break: break-word;

      .path {
        font-size: 3rem;
        margin-left: 0.3em;
        margin-right: 0;
        color: $brand-primary-color-green;
      }
    }
  }
}

.menu.fluid {
  display: flex;
  flex-wrap: wrap;
  width: fit-content!important;

  & > * {
    padding: 10px 15px !important;
  }

  & > *:before {
    display: none !important;
  }
}
