/* ==========================================================================
   Helvetica Compact Rounded
   ========================================================================== */

/* Bold */
@font-face {
  font-family: 'HelveticaRounded-Bold';
  src: url("../fonts/HelveticaRounded-Bold.otf") format('opentype'),
       url("../fonts/HelveticaRounded-Bold.ttf") format('truetype'),
       url("../fonts/HelveticaRounded-Bold.woff") format('woff');
}
