/* Breakpoints */
$screen-xs-max: 767px;

$screen-sm-min: ($screen-xs-max + 1);
$screen-sm-max: 991px;

$screen-md-min: ($screen-sm-min + 1);
$screen-md-max: 1199px;

$screen-lg-min: ($screen-md-max + 1);
$screen-lg-max: 1599px;

$screen-xl-min: ($screen-lg-max + 1);


/* Colors */
$brand-primary-color-green:   #55B336;
$brand-secondary-color-gray:  #707070;

$brand-text-color-gray:       #5d6a76;
$brand-text-color-dark-gray:  #4a4a4a;
$brand-text-color-dark:       #292929;

/* Globals */
$outer-container-width: 1800px;
$auth-image-url:  '';


/* Header */
$header-height-xs:  80px;
$header-height:     100px;
$header-height-xl:  130px;

$header-spacing:    40px;
$header-spacing-xs: 20px;


/* Brikks */
$brikk-border-radius: 18px;
$brikk-header-height: 80px;
$brick-shadow:        2px 4px 0 rgba(34,36,38,.12), 0 2px 10px 0 rgba(34,36,38,.15);

// Font sizes
$font-size-xl: 18px;
$font-size-medium: 14px;
