@import '~global/styles';
/* ==========================================================================
   BRIKKS.
   ========================================================================== */


/* BRIKKS wrapper.
   ========================================================================== */

div.brikks-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, var(--size--brikk));
  grid-column-gap: var(--size--brikks-spacing);
  grid-row-gap: var(--size--brikks-spacing);
  justify-content: center;
  margin: 0 auto;
  padding: 30px 18px;

  & > * {
    height: var(--size-brikk);
    transition: transform 1s;
  }

  /* Single BRIKK.
     ========================================================================== */

  /* Outer BRIKK wrapper */
  .brikk {
    display: inline-block;
    position: relative;
    width: 100%;

    &.wiggle {
      animation: wiggle 0.3s ease infinite;
      cursor: pointer;
    }

    &.dragged {
      border: 3px dashed $brand-primary-color-green;
      border-radius: $brikk-border-radius;
      animation: none;

      & > * {
        opacity: 0;
      }
    }

    @keyframes wiggle {
      0% {transform: rotateZ(0.5deg);}
      50% {transform: rotateZ(-0.5deg);}
      100% {transform: rotateZ(0.5deg);}
    }

    /* Demo ribbon */
    .ribbon {
      top: $brikk-header-height;
      left: -14px;
      z-index: 0;
      position: absolute;
      padding-left: 1.2em;
    }

    /* Inner BRIKK elements wrapper */
    .brikk-content {
      width: var(--size-brikk);
      height: var(--size-brikk);
      background: rgba(255, 255, 255, 0.8);
      border-radius: $brikk-border-radius;
      overflow: hidden;
      margin: 0;
      box-shadow: none!important;

      @include for-mobile {
        width: calc(100vw - #{18px * 2}) !important;
      }

      .brikk-header {
        height: $brikk-header-height;
        position: relative;
        box-shadow: 0 3px 6px rgba(0,0,0,0.15);
        flex-grow: 0;
        padding: 1.1em;

        /* Icon */
        &>img {
          width: 48px;
          margin-right: 1.7em;
        }

        /* Title */
        .header {
          font-size: 20px;
          font-weight: normal;
          margin-top: 0.2em;
        }

        /* Subtitle */
        .meta {
          font-size: 11px;
          font-weight: 300;
          text-transform: uppercase;
          line-height: 1.3em;
        }

        /* Actions */
        .actions-wrapper {
          position: absolute;
          width: 21px;
          top: 15px;
          right: 13px;

          img {
            &.disabled {
              opacity: 0.45;
            }
            &:not(.disabled) {
              cursor: pointer;
            }
            &:first-of-type {
              margin-bottom: 13px;
            }
            &.settings-action { visibility: hidden; }
          }
        }
      }

      .brikk-body {
        border-top: none;
        padding: 1.9em 3em 0.9em;
        overflow: hidden;
        height: 180px;

        /* Loading placeholder */
        .ui.placeholder .line {
          height: 15px;
          margin-bottom: 15px;
          &:before, &:after {
            height: 15px;
          }
        }

        /* Body items */
        .ui.items {
          height: 100%;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;

          .item {
            display: flex;
            align-items: center;
            margin: 0 auto;
            width: 150px;

            /* Display only 4 items */
            &:nth-child(4)~.item {
              display: none;
            }

            @media (max-width: $screen-md-max) {
              flex-direction: row;
            }

            i, img {
              font-size: 1.5em;
              width: 20px;
              color: $brand-primary-color-green;
              margin-right: 1em;
            }
            .content {
              font-size: 1rem;
              .header {
                font-size: 12px;
                font-weight: normal;
                text-transform: uppercase;
                color: inherit !important;
              }
              .description {
                font-size: 1rem;
                font-weight: 600;
                margin-top: -2px;
                line-height: 1em;
                color: inherit !important;
              }
            }
          }
        }
      }

      .brikk-footer {
        border-top: none !important;
        padding: 1.2em;

        button {
          margin: 0;
        }
      }
    }
  }

}


/* My details brikk */
.brikk .my-details {
  text-align: center;

  .avatarImage {
    height: 80px;
    object-fit: cover;
  }

  img {
    background: $brand-primary-color-green;
    border: 2px solid $brand-primary-color-green;
    box-shadow: 0 3px 6px rgba(0,0,0,0.15);
    margin-bottom: 0.6em;
  }

  .text-wrapper {
    font-size: 1rem;
    font-weight: normal;
   // color: $brand-text-color-gray;

    div:first-child {
      font-weight: 600;
    }
  }
}


/* ==========================================================================
   BRIKK Calendar.
   ========================================================================== */


.brikk-calendar {
  width: 138px;
  margin: 0 auto;
  font-weight: bold;

  .ui.card {
    border-radius: $brikk-border-radius;
    overflow: hidden;

    &>.content {
      padding: 0.5rem;
      text-align: center;

      &.header {
        background-color: var(--color--primary);
        font-size: 22px;
        line-height: 1em;
        color: white;
        text-transform: uppercase;
      }

      &.body {
        border-top: none;

        .calendar-date {
          font-size: 48px;
          line-height: 0.9em;
          color: $brand-secondary-color-gray;
          margin: 0 0 0.5rem;
        }

        .calendar-text {
          font-size: 11px;
          line-height: 1.2em;
          color: var(--color--primary-text);

          &.text-1 {
            text-transform: capitalize;
          }
          &.text-2 {
            color: var(--color--brikk-content-text-secondary);
            text-transform: uppercase;
          }
          &.text-3 { }
        }
      }
    }
  }
}


/* ==========================================================================
   BRIKK Slider.
   ========================================================================== */


.ui.raised.card .carousel {
  margin: 0 auto;
  position: relative;
  color: $gray;

  .carouser__slide {

    svg:after {
      display: block;
      content: 'as';
    }
  }

  > button {
    position: absolute;
    top: 45%;
    border: none;
    background: none;
    padding: 0.5rem;

    &[disabled] {
      opacity: 0.5;
      pointer-events: none;
    }

    &.carousel__back-button {
      left: -20px;
    }
    &.carousel__next-button {
      right: -20px;
    }

    &:focus {
      outline: none;
    }
  }

  .carousel__dot-group {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    text-align: center;

    button.carousel__dot {
      width: 10px;
      height: 10px;
      padding: 0;
      margin: 5px;
      border-radius: 50%;
      border: none;
      background-color: var(--color--primary-text);
      opacity: 0.5;

      &.carousel__dot--selected {
        opacity: 1;
        pointer-events: none;
      }
    }
  }
}


/* ==========================================================================
   BRIKK IconNumText.
   ========================================================================== */


.brikk-icon-num-text {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-weight: bold;

  img.path.ui {
    width: 65px;
  }

  .number {
    display: inline-block;
    font-size: 64px;
    margin-left: 0.5rem;
    line-height: 1em;
    color: #55B336;
  }

  .text {
    width: 100%;
    text-align: center;
    font-size: 1rem;
    text-transform: uppercase;
    margin-top: 0.5rem;
    color: $gray;
  }
}
