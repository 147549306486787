/* ==========================================================================
   Global styles. DEPRECATED, use global/styles.
   ========================================================================== */
body, html {
    height: initial!important;
    //min-width: initial; should be added once all brikks use the new component
    min-height: 100vh;
    line-height: initial !important;
}

input,
select,
textarea {
    font-family: $font-family !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0 !important;
}

body {
    font-family: $font-family !important;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    height: 100%;
}


/* ==========================================================================
   SemanticUI overrides.
   ========================================================================== */


body.blurring.dimmable>.dimmer, .ui.dimmer {
    background-color: rgba(0,0,0,0.3);
    perspective: unset!important;
}


/* Checkbox color */
.ui.toggle.checkbox input:focus:checked~.box:before,
.ui.toggle.checkbox input:focus:checked~label:before,
.ui.toggle.checkbox input:checked~.box:before,
.ui.toggle.checkbox input:checked~label:before {
  background-color: var(--color--primary) !important;
}

.ui.toggle.checkbox .box:before, .ui.toggle.checkbox label:before {
    background-color: #A8B7C7 !important;
    border: 1px solid var(--color--primary) !important;
}

.ui.toggle.checkbox input:focus:checked~label,
.ui.toggle.checkbox input:checked~label
{
    color: inherit!important;
}

a {
    .basic.button.ui[role='button'],
    .basic.button.ui[role='button']:hover {
        border: none;
        box-shadow: none;
        font-weight: bold;
    }

    &:hover {
        cursor: pointer;
    }
}
