@import "~global/styles";
/* ==========================================================================
   Helper styles.
   ========================================================================== */


.color-brand-green {
  color: $brand-primary-color-green;
}

.color-brand-gray {
  color: $brand-secondary-color-gray;
}

.bg-color-brand-green {
  background-color: $brand-primary-color-green;
}

.bg-color-brand-gray {
  background-color: $brand-secondary-color-gray;
}


/* ==========================================================================
   Mixins.
   ========================================================================== */


@mixin header-size-padding-top {
  padding-top: 220px;

  @include for-tablet-and-less {
    padding-top: 150px;
  }
}


/* ==========================================================================
   Wiggle effect.
   ========================================================================== */


@-webkit-keyframes wiggle
{
  0% {-webkit-transform: rotateZ(1deg);}
  50% {-webkit-transform: rotateZ(-1deg);}
  100% {-webkit-transform: rotateZ(1deg);}
}
@-moz-keyframes wiggle
{
  0% {-moz-transform: rotateZ(1deg);}
  50% {-moz-transform: rotateZ(-1deg);}
  100% {-moz-transform: rotateZ(1deg);}
}
@-o-keyframes wiggle
{
  0% {-o-transform: rotateZ(1deg);}
  50% {-o-transform: rotateZ(-1deg);}
  100% {-o-transform: rotateZ(1deg);}
}
@keyframes wiggle
{
  0% {transform: rotateZ(0.5deg);}
  50% {transform: rotateZ(-0.5deg);}
  100% {transform: rotateZ(0.5deg);}
}
.wiggle {
  -webkit-animation: wiggle 0.3s ease infinite;
  -moz-animation: wiggle 0.3s ease infinite;
  -o-animation: wiggle 0.3s ease infinite;
  animation: wiggle 0.3s ease infinite;
}


/* ==========================================================================
   Semantic UI overrides
   ========================================================================== */


/* Tables
   ========================================================================== */

table.ui.table {
  background: var(--color--primary-bg);
  color: var(--color--primary-text);

  thead th {
    background: var(--color--primary-bg);
    color: var(--color--primary-text);
    border-bottom: 1px solid var(--color--border);
  }

  tr td {
    border-top: 1px solid var(--color--border);
  }

  &.striped tbody tr:nth-child(2n) {
    background-color: rgba(0,0,50,.04);
  }
}
