@import "~global/styles";

.backgroundColor {
  background-color: #121212 !important;
  color: #fff !important;
}

.cardBackgroundColor {
  background-color: $gray-darker !important;

  button.ui.button:hover {
      background-color: $gray !important;
  }
}

.iconBackgroundSecondaryColor {
  fill: #272727 !important;
  background-color: #272727 !important;
}

.secondaryTextColor {
  color: #fff !important;
}

.textColor {
  color: #fff !important;

  input:checked~label {
    color: #fff !important;
  }

    input {
      color: #fff !important;
      background-color: $gray !important;
    }

  label {
    color: #fff !important;
  }
}

.iconColor {
  &, path {
    fill: #fff !important;
  }

  &, span {
    color: #fff !important;
  }
}

.iconPrimaryColor {
  fill: #55b336 !important;
}

.primaryTextColor {
  color: $primary-green;
}

.pageStyles {
  color: rgba(255, 255, 255, 0.87) !important;
}

.boxShadow {
  box-shadow: none!important;
}

.grayText {
  color: white !important;
}

.basicButtonGroup {
  background-color: $light-gray !important;
}
