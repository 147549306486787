/* ==========================================================================
   AUTH components wrapper
   ========================================================================== */


.brikks-auth.ui.grid {
  display: grid!important;
  justify-items: center;
  justify-content: initial!important;
  @include header-size-padding-top;
  margin: 0;
  min-height: 875px;

  @media (max-width: $screen-xs-max) {
    display: block;
  }


/* Outer wrapper
   ========================================================================== */

  .auth-wrapper.column {
    max-height: 800px;
    max-width: 1300px;
    text-align: left;

    padding: 0 $header-spacing $header-spacing;
    @media (max-width: $screen-xs-max) {
      padding: 0 $header-spacing-xs $header-spacing-xs;
    }

/* Inner wrapper
   ========================================================================== */

    &>.card {
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: $brikk-border-radius;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      @media (max-width: $screen-xs-max) {
        flex-direction: column;

        /* Left column */
        div.image-column {
          width: 100%;
          height: 50vw;
          max-height: 300px;
        }

        /* Right column */
        div.form-column {
          width: 100%;
          padding: 2em 1em;
        }
      }

/* Left column: image
   ========================================================================== */

      .image-column {
        background-image: $auth-image-url;
        background-position: center center;
        background-size: cover;
        width: 50%;
        overflow: hidden;
        border-radius: 0!important;
      }

/* Right column: form
   ========================================================================== */

      .form-column {
        width:50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 4em 2em;
        @media (max-width: $screen-md-max) {
          padding: 3em 1em;
        }

        /* Auth forms */
        &>.form {
          width: 100%;
          max-width: 320px;

          /* Heading */
          .header.ui {
            font-size: 28px;
            font-weight: bold;
            line-height: 1em;

            /* Sub-heading */
            .sub.header {
              font-size: 16px;
              font-weight: 400;
              margin: 1em 0;
            }
          }

          /* Form fields */
          .field {
            margin: 2em 0;

            &:last-of-type {
              margin-bottom: 0;
            }
          }

          /* Button with link */
          .link-submit-block {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;

            a {
              margin: 1em 1em 1em 0;
            }
            button {
              margin-left: auto;
            }
          }
        }

        /* Custom button-link */
        a {
          text-decoration: underline;
        }

      }

    } /* END: Inner wrapper */

  } /* END: Outer wrapper */


/* ==========================================================================
   Login
   ========================================================================== */


  &.login { }


/* ==========================================================================
   Forgot Password & Create Password
   ========================================================================== */


  &.forgot-password { }


/* ==========================================================================
   Forgot Password Verification
   ========================================================================== */


  &.forgot-password-verify { }


/* ==========================================================================
   Change Password
   ========================================================================== */


  &.change-password { }


  /* ==========================================================================
   Change Password Confirmation
   ========================================================================== */


  &.change-password-confirm { }

}
