@import "~global/styles";

.backgroundColor {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.cardBackgroundColor {
  background-color: #fff !important;
}

.textColor {
  color: $text-dark !important;

  input {
    color: $text-dark !important;
  }
}

.iconColor {
  &, path {
    fill: $gray !important;
  }

  &, span {
    color: $gray !important;
  }
}

.secondaryTextColor {
  color: $dark-gray !important;
}

.pageStyles {
  color: $text-dark !important;
}

.grayText {
  color: $gray !important;
}

.basicButtonGroup {
  background-color: #fff !important;
}
